.Admin-Title {
    width: 100%;
    height: 60px;
    background-color: #f5f5f5;

    display: flex;
    justify-content: center;
    align-items: center;

    border-bottom: 1px solid #e5e5e5;
}

.Admin-Title h1 {
    margin: 0;
    padding: 0;
}

/**

    Admin Sidebar Styles

    <section className="admin-sidebar">
        <div className="admin-sidebar__logo" onClick={()=>navigate("/admin")}>
            <img src="/images/logo.png" alt="logo" />
        </div>
        <div className="admin-sidebar__menu">
            {pageInfo.map((page, index) => (
                <div key={index} className="admin-sidebar__menu__item">
                    <div className="admin-sidebar__menu__item__title">{page.name}</div>
                    <ul className="admin-sidebar__menu__item__list">
                        {page.pages.map((item, index) => (
                            <li key={index} className="admin-sidebar__menu__item__list__item" onClick={() => navigate(item.path)}>{item.name}</li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    </section>
*/

/* Path: client\src\styles\admin.css */

.admin-sidebar {
    width: 250px;
    height: calc(100vh - 61px);
    background-color: #f5f5f5;
    position: fixed;
    top: 61px;
    left: 0;
    z-index: 1000;
    /* overflow-y: auto; */
}

.admin-sidebar::-webkit-scrollbar {
    width: 5px;
}

.admin-sidebar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.admin-sidebar::-webkit-scrollbar-thumb {
    background: #888;
}

.admin-sidebar::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.admin-sidebar__logo {
    width: 100%;
    height: 60px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.admin-sidebar__logo img {
    width: 150px;
}

.admin-sidebar__menu {
    width: 100%;
    height: calc(100vh - 60px);
    padding: 20px;
}

.admin-sidebar__menu__item {
    width: 100%;
    margin-bottom: 20px;
}

.admin-sidebar__menu__item__title {
    width: 100%;
    height: 30px;
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin-bottom: 10px;
}

.admin-sidebar__menu__item__list {
    width: 100%;
    height: auto;
    padding-left: 20px;
}

.admin-sidebar__menu__item__list__item {
    width: 100%;
    height: 30px;
    font-size: 14px;
    font-weight: 500;
    color: #333;
    margin-bottom: 5px;
    cursor: pointer;
}

.admin-sidebar__menu__item__list__item:hover {
    color: #000;
}

.admin-sidebar__menu__item__list__item.active {
    color: #000;
    font-weight: 600;
}

.container {
    background-color: rgb(var(--app-background-color));
    width: calc(100% - 250px);
    height: calc(100vh - 61px);
    position: fixed;
    top: 61px;
    right: 0;
    z-index: 1000;
    padding: 20px;
    overflow-y: auto;
}

/**

    Table Styles

    <table className="post-table">
        <thead>
            <tr>
                <th>{type} 일시</th>
                <th>글쓴이</th>
                <th>제목</th>
                <th>비고</th>
            </tr>
        </thead>

        <tbody>
            {data.map((post, index) => {
                return (
                    <tr key={index}>
                        <td>{post.date}</td>
                        <td>{post.author}</td>
                        <td>{post.title}</td>
                        <td>{post.remark}</td>
                    </tr>
                )
            })}
        </tbody>
    </table>

*/

/* Path: client\src\styles\admin.css */

.post-table {
    width: 100%;
    height: auto;
    border-collapse: collapse;
    border: 1px solid #e5e5e5;
}

.post-table thead tr th {
    width: auto;
    height: 40px;
    font-size: 14px;
    font-weight: 600;
    color: #333;
    border-bottom: 1px solid #e5e5e5;
    padding: 0 10px;
}

.post-table tbody tr td {
    width: auto;
    height: 40px;
    font-size: 14px;
    font-weight: 500;
    color: #333;
    border-bottom: 1px solid #e5e5e5;
    padding: 0 10px;
    text-align: center;
}

.post-table tbody tr td button {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.post-table tbody tr:hover {
    background-color: #f5f5f5;
}

.detail-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 20px;
    margin-bottom: 20px;
}

.detail-header > div > div:first-child {
    width: 100px;
    font-weight: bold;
    margin-right: 10px;
}

.detail-header > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}

.detail-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.detail-footer > button {
    padding: 10px;
    width: 150px;
    margin-left: 10px;
    border: none;
}

/**
    다이얼로그 스타일 

*/

.dialog-section {
    width: 100%;
    height: 100%;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
}

.dialog {
    width: 500px;
    height: 300px;
    background-color: white;
    border-radius: 10px;
    padding: 20px;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.dialog-header {
    width: 100%;
    height: 50px;

    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.dialog-close {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
    font-size: 20px;
}
