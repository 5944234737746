.step {
    position: relative;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: inset 0 0 0 0px #ffa000, inset 0 0 0 0 #009688,
        inset 0 0 0 1em #ffa000;
    transition: box-shadow 0.5s;
}
.sr-only:checked + .step ~ .step {
    box-shadow: inset 0 0 0 0px #7fc19d, inset 0 0 0 1em #009688,
        inset 0 0 0 1em #ffa000;
}
.box {
    width: 100%;
    position: relative;
    max-width: 30em;
    margin: 0 auto 3em;
    display: flex;
    justify-content: space-between;
}
.box hr {
    margin: 0;
    border: none;
    color: #7fc19d;
    /* height: 1em; */
}
.box hr,
.box hr:before,
.box hr::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}
.box hr::before,
.box hr::after {
    content: "";
    background-image: linear-gradient(
        transparent calc(50% - 0.5px),
        currentColor calc(50% - 0.5px),
        currentColor calc(50% + 0.5px),
        transparent calc(50% + 0.5px)
    );
}
.box hr::after {
    color: #ffa000;
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition: transform 0.5s linear;
}
.box[data-max="2"] input:nth-of-type(1):checked ~ hr::after {
    transform: scaleX(0);
}
.box[data-max="2"] input:nth-of-type(2):checked ~ hr::after {
    transform: scaleX(1);
}
.box[data-max="3"] input:nth-of-type(1):checked ~ hr::after {
    transform: scaleX(0);
}
.box[data-max="3"] input:nth-of-type(2):checked ~ hr::after {
    transform: scaleX(0.5);
}
.box[data-max="3"] input:nth-of-type(3):checked ~ hr::after {
    transform: scaleX(1);
}
.box[data-max="4"] input:nth-of-type(1):checked ~ hr::after {
    transform: scaleX(0);
}
.box[data-max="4"] input:nth-of-type(2):checked ~ hr::after {
    transform: scaleX(0.3333333333);
}
.box[data-max="4"] input:nth-of-type(3):checked ~ hr::after {
    transform: scaleX(0.6666666667);
}
.box[data-max="4"] input:nth-of-type(4):checked ~ hr::after {
    transform: scaleX(1);
}
.box[data-max="5"] input:nth-of-type(1):checked ~ hr::after {
    transform: scaleX(0);
}
.box[data-max="5"] input:nth-of-type(2):checked ~ hr::after {
    transform: scaleX(0.25);
}
.box[data-max="5"] input:nth-of-type(3):checked ~ hr::after {
    transform: scaleX(0.5);
}
.box[data-max="5"] input:nth-of-type(4):checked ~ hr::after {
    transform: scaleX(0.75);
}
.box[data-max="5"] input:nth-of-type(5):checked ~ hr::after {
    transform: scaleX(1);
}
.box[data-max="6"] input:nth-of-type(1):checked ~ hr::after {
    transform: scaleX(0);
}
.box[data-max="6"] input:nth-of-type(2):checked ~ hr::after {
    transform: scaleX(0.2);
}
.box[data-max="6"] input:nth-of-type(3):checked ~ hr::after {
    transform: scaleX(0.4);
}
.box[data-max="6"] input:nth-of-type(4):checked ~ hr::after {
    transform: scaleX(0.6);
}
.box[data-max="6"] input:nth-of-type(5):checked ~ hr::after {
    transform: scaleX(0.8);
}
.box[data-max="6"] input:nth-of-type(6):checked ~ hr::after {
    transform: scaleX(1);
}
.box[data-max="7"] input:nth-of-type(1):checked ~ hr::after {
    transform: scaleX(0);
}
.box[data-max="7"] input:nth-of-type(2):checked ~ hr::after {
    transform: scaleX(0.1666666667);
}
.box[data-max="7"] input:nth-of-type(3):checked ~ hr::after {
    transform: scaleX(0.3333333333);
}
.box[data-max="7"] input:nth-of-type(4):checked ~ hr::after {
    transform: scaleX(0.5);
}
.box[data-max="7"] input:nth-of-type(5):checked ~ hr::after {
    transform: scaleX(0.6666666667);
}
.box[data-max="7"] input:nth-of-type(6):checked ~ hr::after {
    transform: scaleX(0.8333333333);
}
.box[data-max="7"] input:nth-of-type(7):checked ~ hr::after {
    transform: scaleX(1);
}
.box[data-max="8"] input:nth-of-type(1):checked ~ hr::after {
    transform: scaleX(0);
}
.box[data-max="8"] input:nth-of-type(2):checked ~ hr::after {
    transform: scaleX(0.1428571429);
}
.box[data-max="8"] input:nth-of-type(3):checked ~ hr::after {
    transform: scaleX(0.2857142857);
}
.box[data-max="8"] input:nth-of-type(4):checked ~ hr::after {
    transform: scaleX(0.4285714286);
}
.box[data-max="8"] input:nth-of-type(5):checked ~ hr::after {
    transform: scaleX(0.5714285714);
}
.box[data-max="8"] input:nth-of-type(6):checked ~ hr::after {
    transform: scaleX(0.7142857143);
}
.box[data-max="8"] input:nth-of-type(7):checked ~ hr::after {
    transform: scaleX(0.8571428571);
}
.box[data-max="8"] input:nth-of-type(8):checked ~ hr::after {
    transform: scaleX(1);
}
.box[data-max="9"] input:nth-of-type(1):checked ~ hr::after {
    transform: scaleX(0);
}
.box[data-max="9"] input:nth-of-type(2):checked ~ hr::after {
    transform: scaleX(0.125);
}
.box[data-max="9"] input:nth-of-type(3):checked ~ hr::after {
    transform: scaleX(0.25);
}
.box[data-max="9"] input:nth-of-type(4):checked ~ hr::after {
    transform: scaleX(0.375);
}
.box[data-max="9"] input:nth-of-type(5):checked ~ hr::after {
    transform: scaleX(0.5);
}
.box[data-max="9"] input:nth-of-type(6):checked ~ hr::after {
    transform: scaleX(0.625);
}
.box[data-max="9"] input:nth-of-type(7):checked ~ hr::after {
    transform: scaleX(0.75);
}
.box[data-max="9"] input:nth-of-type(8):checked ~ hr::after {
    transform: scaleX(0.875);
}
.box[data-max="9"] input:nth-of-type(9):checked ~ hr::after {
    transform: scaleX(1);
}
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.content-header {
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
}

.content-title-step {
    font-size: 3rem;
    font-weight: 700;
    font-style: italic;
    text-align: center;
    display: inline;
    width: 40px;
}

.content-title-input {
    padding-bottom: 10px;
    border: 0;
    border-bottom: 1px solid #3f3f3f;
    width: calc(90% - 80px);
    margin-left: 5%;
    margin-right: 5%;
}

.content-title-image {
    width: 50px;
    height: 50px;
}
.content-title-image img {
    width: 100%;
    height: 100%;
}

.content-body {
    display: flex;
    margin: 30px 0px;
}

.content-body-img {
    width: 40%;
}
.content-body-img img {
    width: 100%;
    height: 100%;
}

.content-body-content {
    width: 60%;
}

.content-footer {
    display: flex;
    justify-content: space-between;
}

.content-footer div:first-child button {
    margin-right: 10px;
}

.title-img {
    text-align: center;
    width: 100%;
    height: 220px;
}
.title-img img {
    cursor: pointer;
    width: auto;
    height: 100%;
}

.footer {
}

.footer-footer {
}
