h2.heading {
    margin-bottom: 1em;
}

ol.listTerms {
    list-style-type: terms;
    list-style-position: inside;
}

ol.listCircledDecimal {
    list-style-type: circled-decimal;
}

.terms li {
    margin-block-start: 1em;
    margin-block-end: 1em;
}

@counter-style terms {
    system: numeric;
    symbols: "0" "1" "2" "3" "4" "5" "6" "7" "8" "9";
    prefix: "제";
    suffix: "조 ";
}

@counter-style circled-decimal {
    system: fixed 0;
    symbols: "\24EA""\2460""\2461""\2462""\2463""\2464""\2465""\2466""\2467""\2468""\2469""\246A""\246B""\246C""\246D""\246E""\246F""\2470""\2471""\2472""\2473""\3251""\3252""\3253""\3254""\3255""\3256""\3257""\3258""\3259""\325a""\325b""\325c""\325d""\325e""\325f""\32b1""\32b2""\32b3""\32b4""\32b5""\32b6""\32b7""\32b8""\32b9""\32ba""\32bb""\32bc""\32bd""\32be""\32bf";
    suffix: " ";
}
