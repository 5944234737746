.edit-body > div:first-child {
    font-weight: bold;
    margin-bottom: 20px;
}

.edit-reason{
    width: 100%;
    height: 300px;

    background-color: #F4F4F4;
    padding: 20px;

    display: flex;
    align-items: center;
}

.dialog-body{
    width: 100%;
    height: 150px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.dialog-body > textarea{
    width: 100%;
    height: 100%;
    resize: none;
    border: none;
    outline: none;
    padding: 10px;

    background-color: #F4F4F4;
}
