.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    text-align: center;
}

.notice {
    margin-bottom: 1rem;
}

.button {
    display: flex;
    width: max-content;
    padding: 0;
}

.terms {
    margin-top: 1rem;
    color: rgb(var(--app-text-color), 0.6);
}
