.navbar {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    position: sticky;
    top: 0;
    padding: 1rem 2rem;
    background-color: rgb(var(--app-background-color));
    border-bottom: 0.124rem solid rgb(var(--white-3-color));
}

.navbar__logo {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.navbar__logo img {
    height: 45px;
}

.navbar__links {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.navbar__profile {
    width: 45px;
    height: 45px;
}
.navbar__profile img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    cursor: pointer;
}

.navbar__addmenu {
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100px;
    border: 1px solid #c6c6c6;
    right: 50px;
}

.navbar__addmenu div {
    padding: 5px 10px;
    cursor: pointer;
}

.navbar__addmenu div:first-child {
    border-bottom: 1px solid #c6c6c6;
}
