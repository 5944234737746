.header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.header__imageWrapper {
    display: flex;
    height: 40vh;
    border-radius: 1rem 1rem 0 0;
    overflow: hidden;
}

.header__imageWrapper img {
    object-fit: cover;
}

.header__title,
.header__profile {
    padding: 0.5em 1em;
    background-color: var(--app-background-color);
    border: 0.124rem solid rgba(var(--app-text-color), 0.3);
    border-radius: 1rem;
    box-shadow: 0 0.25rem 0.25rem rgba(var(--app-text-color), 0.2);
}

.header__title {
    border-radius: 0 0 1rem 1rem;
}

.header__profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.header__profile img {
    width: 2rem;
    aspect-ratio: 1;
    border-radius: 50%;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 5%;
}

.content img {
    margin: 1rem 0;
    border-radius: 0.5rem;
}

.comment__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.comment__header img {
    width: 3rem;
    aspect-ratio: 1;
    border-radius: 50%;
}

.comment p {
    margin: 1rem 0;
}
